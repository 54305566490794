<div class="page form-height">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div *ngIf="!loading" class="max-w-1000 m-auto">

    <dx-form id="form" [(formData)]="taskControlForm" [readOnly]="false" [showColonAfterLabel]="true"
      validationGroup="formData" labelLocation="left" [minColWidth]="400" [colCount]="3" [width]="800">

      <!-- <dxi-item dataField="cutOffTimeToPopulateNextStart" editorType="dxDateBox"
        [editorOptions]="{ width: '120px', type: 'time' }">
        <dxo-label location="left" alignment="right"
          text="Cut off time when setting the start date of a subsequent task">
        </dxo-label>
      </dxi-item> -->

      <dxi-item dataField="subContractorPayStartDate" editorType="dxDateBox" colSpan="3"
        [editorOptions]="{ width: '120px', type: 'date' }">
        <dxo-label location="left" alignment="right" text="The first cut-off date for paying subcontractors">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="daysToPaySubContractorsFromCutOff" editorType="dxNumberBox" colSpan="3"
        [editorOptions]="{ width: '120px' }">
        <dxo-label location="left" alignment="right" text="Calendar days to pay subcontractors from cut-off">
        </dxo-label>
      </dxi-item>

      <dxi-item *ngIf="accountingSystemType !== 2" dataField="clientsDaysToPay" editorType="dxNumberBox" colSpan="2"
        [editorOptions]="{ width: '120px' }">
        <dxo-label location="left" alignment="right" text="Payment terms for due date of claims">
        </dxo-label>
      </dxi-item>

      <dxi-item *ngIf="accountingSystemType !== 2" dataField="isClientsDaysToPayWorkingDays" editorType="dxCheckBox"
        colSpan="1" [editorOptions]="{ width: '50px' }">
        <dxo-label location="left" alignment="right" text="Working Days">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="callUpEmailStoreAddress" editorType="dxTextBox" colSpan="3">
        <dxo-label location="left" alignment="right" text="BCC Call-Up emails to">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="infoEmailStoreAddress" editorType="dxTextBox" colSpan="3">
        <dxo-label location="left" alignment="right" text="BCC Info emails to">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="quoteRequestReplyToEmail" editorType="dxTextBox" colSpan="3">
        <dxo-label location="left" alignment="right" text="Quote Request reply email address">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="maintenanceReplyToEmail" editorType="dxTextBox" colSpan="3">
        <dxo-label location="left" alignment="right" text="Maintenance reply email address">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="ccToSiteManager" editorType="dxCheckBox" colSpan="3">
        <dxo-label location="left" alignment="right" text="CC Call-Ups to Site Manager by default">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="isCashFlowUsingPurchaseOrders" editorType="dxCheckBox" colSpan="3">
        <dxo-label location="left" alignment="right" text="Cash Flow uses Purchase Orders if Orders locked">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="isCashFlowUsingEstimating" editorType="dxCheckBox" colSpan="3">
        <dxo-label location="left" alignment="right" text="Cash Flow uses Estimating if Orders not locked">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="expectedPercentCancellation" editorType="dxNumberBox" colSpan="3">
        <dxo-label location="left" alignment="right"
          text="Cash Flow - expected cancellation % prior to finance approval">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="extraDaysForClaimDueForCashFlow" editorType="dxNumberBox" colSpan="3">
        <dxo-label location="left" alignment="right" text="Cash Flow - extra payment days allowed for claims">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="wipOptionId" editorType="dxSelectBox" [editorOptions]="wipSelectOptions" colSpan="3">
        <dxo-label location="left" alignment="right" text="WIP report uses">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="addContractualDaysAsWorkingDays" editorType="dxCheckBox" colSpan="3">
        <dxo-label location="left" alignment="right"
          text="Add Additional Days to Contractual Completion as working days">
        </dxo-label>
      </dxi-item>

      <dxi-item *ngIf="emailUpdatesToClientsActive" dataField="clientEmailMinLeadDays" editorType="dxNumberBox"
        colSpan="3" [editorOptions]="{ width: '120px' }">
        <dxo-label location="left" alignment="right" text="Client Email Updates - Min days before start">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="emailAttachmentsUsesDownloadLink" editorType="dxCheckBox" colSpan="3">
        <dxo-label location="left" alignment="right" text="Call-Up email attachments uses a download link">
        </dxo-label>
      </dxi-item>

      <dxi-item dataField="modifiedUser" [editorOptions]="{ disabled: true }" editorType="dxTextBox" colSpan="3">
        <dxo-label location="left" alignment="right" text="Last modified by"></dxo-label>
      </dxi-item>

      <dxi-item dataField="modifiedDate" [editorOptions]="{ width: '120px', disabled: true }" editorType="dxDateBox"
        colSpan="3">
        <dxo-label location="left" alignment="right" text="Last modified"></dxo-label>
      </dxi-item>

      <dxi-item itemType="button" horizontalAlignment="right" [buttonOptions]="buttonOptions" colSpan="3">
      </dxi-item>

    </dx-form>
  </div>
</div>
