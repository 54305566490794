<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="recipeGrid" class="m-auto grid-height" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate2')"
    (onCellPrepared)="onCellPrepared($event)" (onInitNewRow)="onInitNewRow($event)"
    (onRowPrepared)="onRowPrepared($event)" (onExporting)="gridService.onExporting($event, 'Forecast')"
    (onEditorPreparing)="onEditorPreparing($event)" (onSaved)="onSaved()">

    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="150px"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="canRunCashFlow" [allowUpdating]="canUpdateRecords"
      [allowDeleting]="canRunCashFlow" [useIcons]="true" refreshMode="reshape">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"> <dxo-search [enabled]="true"></dxo-search> </dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

    <dxi-column dataField="jobId" caption="Job" [width]="90" editCellTemplate="codeDropDown"
      [calculateSortValue]="calculateJobSortValue" [setCellValue]="setJobIdCellValue" sortOrder="asc">
      <dxo-lookup [dataSource]="jobData" displayExpr="jobNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="!showSiteAddress" dataField="description" cellTemplate="noWrapTemplate" [minWidth]="100"
      sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column *ngIf="showSiteAddress" dataField="siteAddress" cellTemplate="noWrapTemplate" [minWidth]="100"
      [calculateCellValue]="calculateSiteAddress">
    </dxi-column>

    <dxi-column dataField="claimScheduleId" caption="Claim Template" dataType="number" width="130"
      cellTemplate="noWrapTemplate">
      <dxo-lookup [dataSource]="claimMasters" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="salesDate" dataType="date" width="90" alignment="center" cellTemplate="dateTemplate">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column *ngIf="canUpdateRecords" dataField="jobCostExcGST" caption="Job Cost Ex-GST (Ex Fixed Costs)"
      dataType="number" format="#,###" width="120"></dxi-column>

    <!-- additional cost that can be negative that is used to add to the job cost - including if we use the greater of estimated or scheduled -->
    <dxi-column dataField="fixedCostNotInJobCost" caption="Extra Cost for Cashflow Only" dataType="number"
      format="#,###" width="120"></dxi-column>

    <dxi-column dataField="contractPriceIncGST" caption="Contract Price Inc-GST" dataType="number" format="#,###"
      width="120"></dxi-column>

    <dxi-column *ngIf="showForecastTask" dataField="forecastTaskId" caption="Forecast Task" dataType="number"
      [minWidth]="100" [allowEditing]="false" cellTemplate="noWrapTemplate">
      <dxo-lookup [dataSource]="taskList" displayExpr="taskTitle" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="showForecastTask" dataField="forecastTaskStartDate" caption="Forecast Task Start" dataType="date"
      width="100" alignment="center" [allowEditing]="false" cellTemplate="dateTemplate">
    </dxi-column>

    <dxi-column *ngIf="showForecastTask" dataField="forecastTaskCompletionDate" caption="Forecast Task Completion"
      dataType="date" width="100" alignment="center" [allowEditing]="false" cellTemplate="dateTemplate">
    </dxi-column>

    <dxi-column dataField="foreCastStartDate" caption="Forecast Site Start" dataType="date" width="103"
      alignment="center" [allowEditing]="false" cellTemplate="dateTemplate">
    </dxi-column>

    <dxi-column dataField="targetStartDate" caption="Target Start" dataType="date" width="110" alignment="center"
      cellTemplate="dateTemplate" [editorOptions]="{ showClearButton: true }">
    </dxi-column>

    <dxi-column dataField="fixedStartDate" caption="Fixed Start" dataType="date" width="100" alignment="center"
      cellTemplate="dateTemplate" [editorOptions]="{ showClearButton: true }">
    </dxi-column>

    <dxi-column *ngIf="showContractualCompletion" dataField="contractualWorkingDays"
      [caption]="addContractualDaysText" dataType="number" width="110" alignment="center"
      [editorOptions]="{ showClearButton: true }" [setCellValue]="setContractualWorkingDays">
    </dxi-column>

    <dxi-column *ngIf="showContractualCompletion" dataField="originalContractualCompletion"
      caption="Original Contractual Completion" dataType="date" width="100" alignment="center"
      cellTemplate="dateTemplate" [editorOptions]="{ showClearButton: true }">
    </dxi-column>

    <dxi-column *ngIf="showContractualCompletion" dataField="extensionDays" caption="Extension Days from Variations"
      dataType="number" width="80" alignment="center" [allowEditing]="false"
      [calculateCellValue]="calculateExtensionDays">
    </dxi-column>
    `
    <dxi-column *ngIf="showContractualCompletion" dataField="currentContractualCompletionDate"
      caption="Current Contractual Completion" dataType="date" width="100" alignment="center"
      cellTemplate="dateTemplate" [calculateCellValue]="calculateCurrentContractualCompletion" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="forecastCompletionDate" caption="Forecast Completion" dataType="date" width="100"
      alignment="center" [allowEditing]="false" format="d-MMM-yy">
    </dxi-column>

    <dxi-column dataField="targetCompletionDate" caption="Workflow Target Completion" dataType="date" width="100"
      alignment="center" [allowEditing]="false" format="d-MMM-yy" [visible]="false">
    </dxi-column>

    <dxi-column dataField="practicalCompletionDate" caption="Practical Completion" dataType="date" width="100"
      alignment="center" [allowEditing]="false" cellTemplate="dateTemplate"
      [calculateCellValue]="calculatePracticalCompletionDate">
    </dxi-column>

    <!-- <dxi-column dataField="skippedCost" caption="Forecast Cost Missing: Expected Completion" width="120"
      alignment="center" [calculateCellValue]="calculateSkippedCost" [allowEditing]="false">
    </dxi-column> -->

    <dxi-column dataField="skippedCost" caption="Cost Invoice Missing" width="120" alignment="center"
      [calculateCellValue]="calculateSkippedCost" [allowEditing]="false">
    </dxi-column>

    <!-- <dxi-column dataField="skippedIncome" caption="Forecast Income Missing: Expected Completion" width="120"
      alignment="center" [calculateCellValue]="calculateSkippedIncome" [allowEditing]="false">
    </dxi-column> -->

    <dxi-column dataField="siteManager" caption="Site Manager" dataType="string" [allowEditing]="false" width="100"
      [calculateCellValue]='calculateSiteManager' minWidth="150">
    </dxi-column>

    <dxi-column *ngIf="showForecastSlab" dataField="salesRep" caption="Sales Rep" dataType="string"
      [allowEditing]="false" width="110" [calculateCellValue]='calculateSalesRep' minWidth="150">
    </dxi-column>

    <dxi-column *ngIf="showForecastSlab" dataField="forecastSlabDate" caption="Forecast Slab" dataType="date"
      width="110" alignment="center" [allowEditing]="false" format="d-MMM-yy">
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" width="90" [allowEditing]="false"
      cellTemplate="noWrapTemplate" [allowEditing]="false" [visible]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" width="95" alignment="center"
      cellTemplate="dateTemplate" [allowEditing]="false" [visible]="false">
    </dxi-column>

    <!-- Contracts Required -->
    <dxi-column dataField="contractsRequiredByDate" dataType="date" [width]="95" alignment="center" format="d-MMM-yy"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateContractsRequiredByDate">
    </dxi-column>

    <dxi-column dataField="daysToContractsRequired" caption="Calendar days to contracts required" dataType="number"
      [width]="85" alignment="center" format="#,##0" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateDaysToContractsRequired">
    </dxi-column>

    <dxi-column dataField="siteStartDate" caption="Actual Start" dataType="date" [width]="95" alignment="center"
      format="d-MMM-yy" [allowEditing]="false" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="agreedCompletionDate" caption="Agreed Completion Date" dataType="date" [width]="95"
      alignment="center" format="d-MMM-yy" [allowEditing]="true" [visible]='false'>
    </dxi-column>


    <div *dxTemplate="let data of 'refreshTemplate'">
      <div>
        <dx-button icon="refresh" (onClick)="refresh()"></dx-button>
      </div>
    </div>

    <div *dxTemplate="let cell of 'noWrapTemplate'">
      <div style="white-space: nowrap; overflow: hidden;">{{cell.text}}</div>
    </div>

    <div *dxTemplate="let cell of 'dateTemplate'">
      <div>{{cell.value | date : "d-MMM-yy"}}</div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate2'">
      <div class="option">
        <div class="label">% Cancellation prior to Formal Approval:</div>
        <div class="value">
          <dx-number-box [(value)]="expectedPercentCancellation" [min]="0" [max]="100" width="60" [readOnly]="true">
          </dx-number-box>
        </div>
      </div>
    </div>


    <div *dxTemplate="let cellInfo of 'codeDropDown'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="jobData" [(value)]="cellInfo.value"
        [showClearButton]="true" displayExpr="jobNumber" valueExpr="id" contentTemplate="contentTemplate"
        (onValueChanged)="onJobValueChanged(cellInfo, $event)">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="jobData" [remoteOperations]="false" height="99%" [showBorders]="true"
            [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value" [selectedRowKeys]="[cellInfo.value]"
            [hoverStateEnabled]="true" [filterRow]="{ visible: true }"
            (onSelectionChanged)="onSelectionChanged(cellInfo, e, $event)">

            <dxo-scrolling mode="virtual"></dxo-scrolling>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>

            <dxi-column dataField="jobId" [visible]='false'></dxi-column>
            <dxi-column dataField="jobNumber" [width]="110"></dxi-column>
            <dxi-column dataField="contractName"></dxi-column>
            <dxi-column dataField="salesDate" [visible]='false'></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>
  </dx-data-grid>

</div>
