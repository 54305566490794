<div class="page">

  <div *ngIf="loadingState">Loading layout please wait<span class="blink_me">...</span></div>
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" #allTaskGrid id="allTaskGrid" class="m-auto" [height]="gridHeight"
    [dataSource]="dataSource" columnResizingMode="widget" [columns]='reportGridService.gridColumns'
    allowColumnReordering="true" allowColumnResizing="true" columnAutoWidth="true" columnRenderingMode="virtual"
    remoteOperations="false" showRowLines="true" showBorders="true" [wordWrapEnabled]="true" filterSyncEnabled="true"
    [filterValue]="filterValue" (onOptionChanged)="gridOptionChanged($event)" (onRowPrepared)="onRowPrepared($event)"
    (onExporting)="onExporting($event)"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate', 'toolbarTemplate2')"
    (onContentReady)="onContentReady()" (onEditorPreparing)="onEditorPreparing($event)"
    (onEditingStart)="onEditingStart($event)" (onSaved)="onSaved()">

    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="150px"></dxo-search-panel>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-editing mode="batch" refreshMode="full" [allowAdding]="false" [allowUpdating]="inEditMode"
      [allowDeleting]="false" [useIcons]="true"></dxo-editing>

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"> <dxo-search [enabled]="true"></dxo-search> </dxo-header-filter>

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="allTaskGrid" [savingTimeout]="500">
    </dxo-state-storing>

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <dx-button text="Layouts" (onClick)="saveState()"> </dx-button>
        <b class="ms-2">{{reportName}}</b>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate2'">
      <div>
        <dx-button *ngIf="!inEditMode" text="Edit" type="default" (onClick)="changEditMode()">
        </dx-button>

        <dx-button *ngIf="inEditMode" icon="close" type="default" (onClick)="changEditMode()">
        </dx-button>
      </div>
    </div>

    <div *dxTemplate="let cellInfo of 'commentCellTemplate'">
      <div id="stringComment" class='mx-0 px-0 ellipsis'>{{cellInfo.value}}</div>
    </div>

    <dxo-summary>
      <dxi-total-item column="jobNumber" summaryType="count" displayFormat="{0} jobs">
      </dxi-total-item>
      <dxi-group-item column="jobNumber" summaryType="count" displayFormat="{0} jobs">
      </dxi-group-item>

      <dxi-total-item column="depositAmount" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="depositPaid" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>

      <dxi-total-item column="salesQuotePrice" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="salesQuotePrice" summaryType="avg" valueFormat="#,##0" displayFormat="Avg: {0}">
      </dxi-total-item>
      <dxi-total-item column="contractPrice" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="contractPrice" summaryType="avg" valueFormat="#,##0" displayFormat="Avg: {0}">
      </dxi-total-item>
      <dxi-total-item column="totalContractPrice" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalContractPrice" summaryType="avg" valueFormat="#,##0" displayFormat="Avg: {0}">
      </dxi-total-item>

      <dxi-total-item column="nextClaimAmount" summaryType="sum" valueFormat="#,##0" displayFormat="${0}">
      </dxi-total-item>
      <dxi-group-item *ngIf="nextClaimAmountVisible" column="nextClaimAmount" summaryType="sum" valueFormat="#,##0"
        displayFormat="Claims ${0}">
      </dxi-group-item>
    </dxo-summary>
  </dx-data-grid>

  <span *ngIf="!loading">{{minuteCountdown}} minutes before auto refresh</span>
</div>