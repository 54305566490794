<div class="page">
  <dx-tree-list id="jobDocumentsTree" class="max-w-900 m-auto maintenancegrid-height" [dataSource]="dataSource"
    keyExpr="id" parentIdExpr="parentId" [showRowLines]="true" [showBorders]="true" [columnAutoWidth]="false"
    [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-search-panel [visible]="true" width="200"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true">
    </dxo-editing>

    <dxi-column dataField="description" [allowSorting]="false" sortOrder="asc"></dxi-column>

    <!-- <dxi-column caption="Truth Engine Info"> -->
    <dxi-column dataField="isShownInInfo" caption="Show In Info" dataType="boolean" [width]="80"
      [setCellValue]="setIsShownInInfo"></dxi-column>

    <dxi-column dataField="isCustomerViewable" caption="Customer Viewable" dataType="boolean" [width]="80">
    </dxi-column>

    <dxi-column dataField="isTradeViewable" caption="Trade Viewable" dataType="boolean" [width]="80"></dxi-column>

    <dxi-column dataField="callUpDocsTypeId" caption="Call-Up Doc Type" dataType="number" [width]="170"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="callUpDocsTypes" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isEmailFolder" caption="Email Folder" dataType="boolean" [width]="70"></dxi-column>
    <dxi-column dataField="isClaimsDocumentFolder" caption="Claims Folder" dataType="boolean" [width]="70"></dxi-column>
    <!-- </dxi-column> -->

  </dx-tree-list>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
