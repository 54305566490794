<div class="modal-header">
  <h2 class="modal-title">Grid Layouts</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card">

    <div class="card-body">

      Add a row to save the current layout
      <br>
      You can edit the name or privacy of saved layouts

      <dx-data-grid #stateGrid id="stateGrid" [dataSource]="dataSource" [remoteOperations]="false" [height]="gridHeight"
        [allowColumnResizing]="true" columnResizingMode="widget" [showBorders]="true" [focusedRowEnabled]="true"
        [showRowLines]="true" [focusedRowKey]="selectedLayout" [selectedRowKeys]="selectedLayout"
        [hoverStateEnabled]="true" [wordWrapEnabled]="true" [rowAlternationEnabled]="false"
        [scrolling]="{ mode: 'virtual', preloadEnabled: true }" (onSelectionChanged)="onSelectionChanged($event)"
        class="width100">

        <dxo-scrolling mode="standard"></dxo-scrolling>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-search-panel [visible]="true" location="before" width="150px"></dxo-search-panel>
        <dxo-load-panel [enabled]="true"> </dxo-load-panel>
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

        <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
          refreshMode="full">
        </dxo-editing>

        <dxo-paging [enabled]="false"></dxo-paging>

        <dxi-column dataField="description"></dxi-column>

        <dxi-column *ngIf="showLoadDefaultButton" dataField="privateStore" dataType='boolean' width="70"
          caption="Private"></dxi-column>

        <dxi-column dataField="lastLoadedDate" caption="Last Loaded" dataType="date" [visible]='false' [width]="90"
          format="d-MMM-yy" alignment="center"></dxi-column>

        <dxi-column dataField="lastLoadedUser" caption="Loaded By" dataType="string" [visible]='false' [width]="130"
          [calculateCellValue]="calculateLoadedBy"></dxi-column>

        <dxi-column type="buttons" [width]="90" [fixed]="true">
          <dxi-button name="edit"></dxi-button>
          <dxi-button name="delete"></dxi-button>
          <dxi-button template="favouritesCommand">
            <div class="favButtonClass" *dxTemplate="let data of 'favouritesCommand'">
              <i *ngIf="!data.data.isFavourite" class="material-icons blue" (click)="setFavourite(data)">star_border</i>
              <i *ngIf="data.data.isFavourite" class="material-icons orange" (click)="setFavourite(data)">star</i>
            </div>
          </dxi-button>
        </dxi-column>
      </dx-data-grid>

    </div>


    <div class="card-footer">
      <button type="button" class="btn btn-sm btn-primary" (click)="selectLayout()" [disabled]="!selectedLayout">
        Load selected layout
      </button>

      <button *ngIf="showSaveLayoutButton" type="button" class="btn btn-sm btn-primary ms-2" (click)="saveLayout()"
        [disabled]="!selectedLayout">
        Save over selected layout
      </button>

      <button *ngIf="showLoadDefaultButton" type="button" class="btn btn-sm btn-danger ms-2" (click)="loadDefault()">
        {{loadDefaultLabel}}
      </button>
    </div>
  </div>
</div>

<dx-popup class="popup" [width]="220" [height]="170" [showTitle]="true" title="Update" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="savePopupVisible">
  <div *dxTemplate="let data of 'content'">
    <div>Update this layout?</div>
    <br>
    <dx-button id="saveLayoutButton" text="Go" type="success" (onClick)="saveLayoutGo()">
    </dx-button>
  </div>
</dx-popup>