import { RoleTypeEnum } from './../dtos/role-type.enum';
import { User } from './../dtos/user';
import { UtilsService } from './../services/utils.service';
import { StateStore } from '../shared/state-store/state-store';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription, timer } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';
import { GridService } from '../services/grid.service';
import { UserService } from '../services/felixApi/user.service';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { TaskService } from '../services/felixApi/task.service';
import { TaskStatusEnum } from '../dtos/task-status.enum';
import { Job } from '../dtos/job';
import { JobService } from '../services/felixApi/job.service';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { Task, TaskChange } from '../dtos/task';
import { TemplateTaskHeader } from '../dtos/templateTaskHeader';
import { JobWorkFlowService } from '../services/felixApi/job-work-flow.service';
import { JobWorkFlow } from '../dtos/job-work-flow';
import { debounceTime } from 'rxjs/operators';
import { Vendor } from '../dtos/vendor';
import { JobDocument } from '../dtos/job-document';
import { TemplateTask } from '../dtos/templateTask';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateStoreComponent } from '../shared/state-store/state-store.component';
import { StateStoreTypeEnum } from '../dtos/state-store-type-enum';
import { JobSearchModalComponent } from '../shared/job-search-modal/job-search-modal.component';
import { JobSearchTypeEnum } from '../dtos/job-search-type.enum';
import { AuthService } from '../services/auth.service';
import { Variation } from '../dtos/variation';
import { CancelJobTasksComponent } from './cancel-job-tasks/cancel-job-tasks.component';
import { TrackingColourEnum } from '../dtos/tracking-colour.enum';
import { ReassignJobTasksComponent } from './reassign-job-tasks/reassign-job-tasks.component';
import { Router } from '@angular/router';
import { Trade } from '../dtos/trade';
import DateBox, { Properties } from "devextreme/ui/date_box";
import { TaskDocumentsComponent } from '../shared/task-documents/task-documents.component';
import { QuestionComponent } from '../shared/question/question.component';
import { PurchaseOrder } from '../dtos/purchase-order';
import { TaskMaster } from '../dtos/task-master';

@Component({
  selector: 'js-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  private refreshSubscription: Subscription;
  private emissionCount: number = 0;

  loading = true;
  isMobile: boolean;
  showMyTasksOnly = true;
  showCompleted = false;
  showInProgress = true;
  showCancelled = false;
  showNotStarted = false;
  showJobsOnHold = true;
  subscriptions: Subscription[] = [];
  dataSource: any = {};
  commentHistoryDataSource: CustomStore;
  dropDownOptions: object;
  jobData: CustomStore;
  claimMasters: CustomStore;
  users: CustomStore;
  taskTypes: CustomStore;
  resetPopupVisible = false;
  commentHistoryPopupVisible = false;

  taskStatus = [
    { id: 1, status: 'Not Started' },
    { id: 2, status: 'In Progress' },
    { id: 3, status: 'Hold' },
    { id: 4, status: 'Problem' },
    { id: 5, status: 'Waiting' },
    { id: 8, status: 'Cancelled' },
    { id: 9, status: 'Completed' },
    { id: 10, status: 'Not Applicable' }
  ];
  variationStatus: any[];
  taskStatusList: CustomStore;
  jobs: Job[];
  todayString: string;

  commentHistoryButtonOptions: any = {
    text: 'Comment History',
    type: 'default',
    stylingMode: 'outlined',
    // width: '150',
    useSubmitBehavior: false,
    onClick: () => {
      this.showCommentHistory();
    }
  };
  variationButtonOptions: any = {
    icon: 'download',
    stylingMode: 'outlined',
    useSubmitBehavior: false,
    onClick: () => {
      this.openVariationSelected();
    }
  };
  jobTaskId: number;
  allJobTasks: Task[] = [];
  taskHeaders: TemplateTaskHeader[] = [];
  allTaskHeaders: TemplateTaskHeader[] = [];
  jobWorkFlows: JobWorkFlow[] = [];
  gridHeight: number;
  loadingTasks: boolean;
  isPhoneSize: boolean;
  vendors: Vendor[];
  editPopupWidth: number;
  editPopupHeight: number;
  commentPopupHeight: number;
  commentGridHeight: number;
  jobDropdownWidth: number;
  jobDropdownWindowWidth: number;
  sendPopupVisible: boolean;
  currentTaskTitle: string;
  jobId: number;
  vendorId: number;
  vendorComment: string;
  sendAddendum: boolean;
  ccToSelf = true;
  loadingDocs: boolean;
  jobDocuments: JobDocument[];
  selectedJobDocs: number[] = [];
  callUpSubject: string;
  callUpPopupHeight: number;
  updatedData: any;
  callUpEmail: string;
  currentTemplateTask: TemplateTask;
  callUpTreeHeight: number;
  // currentState: any;
  jobFilterValue: any;
  currentJobWorkflow: JobWorkFlow;
  addendumCaption: string;
  jobVariations: Variation[] = [];
  showFullComments = true;
  selectedTemplateTaskHeaderId: any;
  changes: TaskChange<Task>[] = [];
  savePopupVisible: boolean;
  variationsDataSource: CustomStore;
  salesRepWording = '';
  deletePopupVisible: boolean;
  deleteInProgress: boolean;
  recordToDelete: Task;
  checkPopupVisible: boolean;
  trades: Trade[];
  jobVariationId: number;
  jobVariationStatusId: number;
  updatingVariation: boolean;
  canReadAmountsPermission: boolean;
  startFutureDays: number; // default off
  iPadShowDeleteButton: boolean;
  iPadButtonsWidth: number;
  liaisonWording: string;
  prestartOfficerWording: string;
  siteManagerWording: string;
  constructionCoordinatorWording: string;
  areaConstructionManagerWording: string;
  designerWording: string;
  salesEstimatorWording: string;
  schedulerWording: string;
  salesRepIsActive: boolean;
  liaisonIsActive: boolean;
  prestartOfficerIsActive: boolean;
  siteManagerIsActive: boolean;
  constructionCoordinatorIsActive: boolean;
  areaConstructionManagerIsActive: boolean;
  designerIsActive: boolean;
  salesEstimatorIsActive: boolean;
  schedulerIsActive: boolean;
  draftspersonIsActive: boolean;
  preConstructionCoordinatorIsActive: boolean;
  accountsIsActive: boolean;
  draftspersonWording: string;
  preConstructionCoordinatorWording: string;
  accountsWording: string;
  currentJobTask: Task;
  editDueDateOrCommentPopupVisible: boolean;
  dueDate: Date;
  officeComment: string;
  cancelOldTasksModeOn: boolean;
  isSmallScreen: boolean;
  selectedJobTaskIds: number[] = [];
  cancelTasksPopupVisible: boolean;
  cancelTasksButton: string;
  includeStartedTasksForCancel: boolean;
  minuteCountdown = 0;
  inEditMode: boolean;
  purchaseOrders: PurchaseOrder[];
  selectedPurchaseOrderId: number;
  iPadShowGoToJobDataButton: boolean;
  currentTaskMaster: TaskMaster;
  salesAdministratorIsActive: boolean;
  salesAdministratorWording: string;
  maintenanceCoordinatorIsActive: boolean;
  maintenanceCoordinatorWording: string;

  constructor(
    private taskService: TaskService,
    private auth: AuthService,
    private maintenanceService: MaintenanceService,
    private modalService: NgbModal,
    private notiService: NotificationService,
    private globalService: GlobalService,
    protected gridService: GridService,
    protected jobWorkFlowService: JobWorkFlowService,
    private userService: UserService,
    private jobService: JobService,
    private utilsService: UtilsService,
    private router: Router
  ) {
    this.dropDownOptions = { width: 900 };
    this.onInitNewRow = this.onInitNewRow.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.getTaskList = this.getTaskList.bind(this);
    this.setTaskMasterIdCellValue = this.setTaskMasterIdCellValue.bind(this);
    this.setTaskTypeCellValue = this.setTaskTypeCellValue.bind(this);
    this.calculateContractName = this.calculateContractName.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateJobSortValue = this.calculateJobSortValue.bind(this);
    this.calculateJobGroupValue = this.calculateJobGroupValue.bind(this);
    this.calculateJobGroupDisplay = this.calculateJobGroupDisplay.bind(this);
    this.calculateTaskSortValue = this.calculateTaskSortValue.bind(this);
    this.setJobIdCellValue = this.setJobIdCellValue.bind(this);
    this.setStartDateCellValue = this.setStartDateCellValue.bind(this);
    this.setDueDateCellValue = this.setDueDateCellValue.bind(this);
    this.setTemplateTaskHeaderIdCellValue = this.setTemplateTaskHeaderIdCellValue.bind(this);
    this.cancelClickHandler = this.cancelClickHandler.bind(this);
    this.saveClickHandler = this.saveClickHandler.bind(this);
    this.sendTask = this.sendTask.bind(this);
    this.setVendorEmail = this.setVendorEmail.bind(this);
    this.onVendorSelectionChanged = this.onVendorSelectionChanged.bind(this);
    this.calculateCallUpDocsType = this.calculateCallUpDocsType.bind(this);
    this.jobSearch = this.jobSearch.bind(this);
    this.calculateVariationTitle = this.calculateVariationTitle.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.setSentCellValue = this.setSentCellValue.bind(this);
    this.setEndDateCellValue = this.setEndDateCellValue.bind(this);
    this.calcOriginalDueDate = this.calcOriginalDueDate.bind(this);
    this.calculateMasterJob = this.calculateMasterJob.bind(this);
    this.calculateSentFromQueueStatus = this.calculateSentFromQueueStatus.bind(this);
    this.openVariationDropdown = this.openVariationDropdown.bind(this);
    this.calculateSalesRep = this.calculateSalesRep.bind(this);
    this.calculateCurrentActivity = this.calculateCurrentActivity.bind(this);
    this.goToJobData = this.goToJobData.bind(this);
    this.calculateKeyTaskValue = this.calculateKeyTaskValue.bind(this);
    this.deleteTaskPopup = this.deleteTaskPopup.bind(this);
    this.deleteTasksGo = this.deleteTasksGo.bind(this);
    this.upSertJobTasks = this.upSertJobTasks.bind(this);
    this.checkTaskPopup = this.checkTaskPopup.bind(this);
    this.checkTasksGo = this.checkTasksGo.bind(this);
    this.calculateTrade = this.calculateTrade.bind(this);
    this.calculateTradeRegion = this.calculateTradeRegion.bind(this);
    this.calculateVariationStatus = this.calculateVariationStatus.bind(this);
    this.setVariationIdCellValue = this.setVariationIdCellValue.bind(this);
    this.openVariation = this.openVariation.bind(this);
    this.openVariationSelected = this.openVariationSelected.bind(this);
    this.calculateHouseType = this.calculateHouseType.bind(this);
    this.onCellPrepared = this.onCellPrepared.bind(this);
    this.calculateLiaison = this.calculateLiaison.bind(this);
    this.calculatePrestartOfficer = this.calculatePrestartOfficer.bind(this);
    this.calculateSiteManager = this.calculateSiteManager.bind(this);
    this.calculateConstructionCoordinator = this.calculateConstructionCoordinator.bind(this);
    this.calculateAreaConstructionManager = this.calculateAreaConstructionManager.bind(this);
    this.calculateDesigner = this.calculateDesigner.bind(this);
    this.calculateSalesEstimator = this.calculateSalesEstimator.bind(this);
    this.calculateScheduler = this.calculateScheduler.bind(this);
    this.calculateDraftsperson = this.calculateDraftsperson.bind(this);
    this.calculatePreConstructionCoordinator = this.calculatePreConstructionCoordinator.bind(this);
    this.calculateAccounts = this.calculateAccounts.bind(this);
    this.attachDocs = this.attachDocs.bind(this);
    this.editDueDateOrComment = this.editDueDateOrComment.bind(this);
    this.calculateVariationEstimator = this.calculateVariationEstimator.bind(this);
    this.onTasksSaved = this.onTasksSaved.bind(this);
    this.calculateDaysSinceCreated = this.calculateDaysSinceCreated.bind(this);
    this.calculateDaysSinceStart = this.calculateDaysSinceStart.bind(this);
    this.onEditCancelled = this.onEditCancelled.bind(this);
    this.calculateDivision = this.calculateDivision.bind(this);
    this.calculateJobWarning = this.calculateJobWarning.bind(this);
    this.calculateContractsRequiredByDate = this.calculateContractsRequiredByDate.bind(this);
    this.calculateDaysToContractsRequired = this.calculateDaysToContractsRequired.bind(this);
    this.calculateLineManager = this.calculateLineManager.bind(this);
    this.setStatusCellValue = this.setStatusCellValue.bind(this);
    this.calculateSalesAdministrator = this.calculateSalesAdministrator.bind(this);
    this.calculateMaintenanceCoordinator = this.calculateMaintenanceCoordinator.bind(this);

    DateBox.defaultOptions<Properties>({
      device: [
        { deviceType: 'desktop' },
        { deviceType: 'tablet' },
        { deviceType: 'phone' }
      ],
      options: {
        pickerType: 'calendar'
      }
    });

  }

  ngOnInit(): void {
    const currentState = JSON.parse(localStorage.getItem('jobtaskgrid'));
    this.getStateVariables(currentState);

    // user can set the future start days locally
    let futureDaysStore = JSON.parse(localStorage.getItem('jobtask-start-future-days'));

    if (futureDaysStore) {
      this.startFutureDays = futureDaysStore;
    }

    this.variationStatus = [
      { id: 1, status: 'Open' },
      { id: 2, status: 'Estimating' },
      { id: 5, status: 'Pending Approval' },
      { id: 6, status: 'Cancelled' },
      { id: 7, status: 'Approved' },
      { id: 8, status: 'Actioned' },
      { id: 9, status: 'Invoiced' }
    ];
    // this.variationStatusList = new DataSource({
    //   key: 'id',
    //   loadMode: 'raw',
    //   load: () => this.variationStatus
    // });

    this.addendumCaption = 'Send ' + this.globalService.getAddendumName();
    this.setGridMeasurements(true);
    this.subscribeToInnerHeight();
    this.setupDataRefresh();
    this.getVendors(); // get in the background
    this.getTrades();

    document.onclick = () => this.resetTimer();
    document.onmousemove = () => this.resetTimer();
    document.onscroll = () => this.resetTimer();

    const permissionAmounts = this.auth.getSelectionsPermissions('CanReadAmounts');
    if (permissionAmounts === 'Read' || permissionAmounts === 'Write' || permissionAmounts === 'Admin' || this.auth.isAdminOrSuper()) {
      this.canReadAmountsPermission = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
    document.onclick = null;
    document.onmousemove = null;
    document.onscroll = null;
  }

  subscribeToInnerHeight() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.pipe(debounceTime(250)).subscribe(
        () => {
          setTimeout(() => {
            this.setGridMeasurements(false);
          }, 700); // wait for iPhone
        }
      )
    );
  }

  setGridMeasurements(init: boolean) {
    this.iPadShowDeleteButton = window.innerHeight > 600 && window.innerWidth > 600 ? true : false;
    this.iPadShowGoToJobDataButton = window.innerWidth > 1100 ? true : false;
    this.iPadButtonsWidth = this.iPadShowGoToJobDataButton ? 120 : this.iPadShowDeleteButton ? 100 : 70;
    this.isPhoneSize = false;
    this.isSmallScreen = false;

    if (this.globalService.isTablet || (window.innerHeight < 1400 && window.innerWidth < 1400)) {
      if (init) {
        this.isMobile = true;
      } else if (!this.isMobile) {
        this.isMobile = true;
        this.notiService.showWarning('Changing to restricted view');
        this.getData(true);
      }

      if (window.innerWidth < 600) {
        this.isPhoneSize = true;
      }

      if (window.innerWidth < 600 || window.innerHeight < 600) {
        this.isSmallScreen = true;
      }
    } else {
      if (init) {
        this.isMobile = false;
      } else if (this.isMobile) {
        this.isMobile = false;
        this.notiService.showWarning('Changing to full view');
        this.getData(true);
      }
    }
    this.gridHeight = this.globalService.innerHeight - (this.isMobile ? 60 : 80);
    this.editPopupWidth = this.globalService.innerWidth < 700 ? this.globalService.innerWidth : 700;
    this.editPopupHeight = this.globalService.innerHeight < 725 ? this.globalService.innerHeight : 725;
    this.commentPopupHeight = this.globalService.innerHeight < 640 ? this.globalService.innerHeight : 640;
    this.commentGridHeight = this.globalService.innerHeight < 640 ? this.globalService.innerHeight - 130 : 510;
    this.jobDropdownWidth = this.globalService.innerWidth < 800 ? this.globalService.innerWidth - 100 : 700;
    this.jobDropdownWindowWidth = this.jobDropdownWidth + 20;
    this.callUpPopupHeight = this.globalService.innerHeight < 885 ? this.globalService.innerHeight : 885;
    this.callUpTreeHeight = this.callUpPopupHeight - 530;
    this.callUpTreeHeight = this.callUpTreeHeight < 290 ? 290 : this.callUpTreeHeight;

    this.dropDownOptions = { width: this.jobDropdownWidth + 40 };
  }

  resetTimer() {
    // If the timer is unsubscribed, resubscribe
    if (!this.refreshSubscription) {
      this.setupDataRefresh();
    }
    // Reset the emission count
    this.emissionCount = 0;
  }

  setupDataRefresh(): void {
    if (this.refreshSubscription) {
      return;
    }

    this.emissionCount = 0;
    this.refreshSubscription = timer(0, 60000).subscribe(() => {
      this.emissionCount++;

      if (this.emissionCount > 30) {
        this.refreshSubscription.unsubscribe();
        this.refreshSubscription = null;
      } else {
        if (!this.inEditMode) {
          if (this.minuteCountdown > 0 && !this.loading) {
            this.minuteCountdown--; // Decrement the countdown
          }

          if (this.minuteCountdown <= 0) {
            this.getData(true, true);
            this.minuteCountdown = 10; // Reset countdown after data refresh
          }
        }
      }
    });

    this.subscriptions.push(this.refreshSubscription);
  }



  getVendors() {
    this.subscriptions.push(
      this.userService.getVendors(true, true)
        .subscribe({
          next:
            (vendors) => {
              this.vendors = vendors;
            },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  getTrades() {
    this.subscriptions.push(
      this.maintenanceService.getTradeVendorsData(true)
        .subscribe({
          next: (trades) => {
            this.trades = trades;
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  getData(useCache: boolean, fromTimer: boolean = false) {
    this.loadingTasks = true;
    this.resetCountdown(); // Reset the countdown
    this.inEditMode = false;

    const todaysDate = new Date();
    this.todayString = todaysDate.getFullYear() + '-'
      + ('0' + (todaysDate.getMonth() + 1)).toString().slice(-2) + '-'
      + ('0' + todaysDate.getDate()).slice(-2);

    this.subscriptions.push(
      this.taskService.getJobTasksData(null, this.showMyTasksOnly, this.showInProgress,
        this.showCompleted, this.showCancelled, this.showNotStarted, useCache, fromTimer, this.showJobsOnHold)
        .subscribe({
          next: (jobs) => {
            this.jobs = jobs;
            this.allTaskHeaders = this.maintenanceService.taskHeaders;
            this.jobVariations = this.jobService.jobVariations;
            this.allJobTasks = this.taskService.jobTasks;

            this.jobData = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.jobs.filter(i => i.isActive)
            });

            this.salesRepIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SalesRep)?.isActive;
            this.liaisonIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.LiaisonOfficer)?.isActive;
            this.prestartOfficerIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.PreStartOfficer)?.isActive;
            this.siteManagerIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SiteManager)?.isActive;
            this.constructionCoordinatorIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.ConstructionCoordinator)?.isActive;
            this.areaConstructionManagerIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.AreaConstructionManager)?.isActive;
            this.designerIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.Designer)?.isActive;
            this.salesEstimatorIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SalesEstimator)?.isActive;
            this.schedulerIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SchedulerConstructionEstimator)?.isActive;
            this.draftspersonIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.Draftsperson)?.isActive;
            this.preConstructionCoordinatorIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.PreConstructionCoordinator)?.isActive;
            this.accountsIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.Accounts)?.isActive;
            this.salesAdministratorIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SalesAdministrator)?.isActive;
            this.maintenanceCoordinatorIsActive = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.MaintenanceCoordinator)?.isActive;

            this.salesRepWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SalesRep)?.companyRoleDescription;
            this.liaisonWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.LiaisonOfficer)?.companyRoleDescription;
            this.prestartOfficerWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.PreStartOfficer)?.companyRoleDescription;
            this.siteManagerWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SiteManager)?.companyRoleDescription;
            this.constructionCoordinatorWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.ConstructionCoordinator)?.companyRoleDescription;
            this.areaConstructionManagerWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.AreaConstructionManager)?.companyRoleDescription;
            this.designerWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.Designer)?.companyRoleDescription;
            this.salesEstimatorWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SalesEstimator)?.companyRoleDescription;
            this.schedulerWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SchedulerConstructionEstimator)?.companyRoleDescription;
            this.draftspersonWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.Draftsperson)?.companyRoleDescription;
            this.preConstructionCoordinatorWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.PreConstructionCoordinator)?.companyRoleDescription;
            this.accountsWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.Accounts)?.companyRoleDescription;
            this.salesAdministratorWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SalesAdministrator)?.companyRoleDescription;
            this.maintenanceCoordinatorWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.MaintenanceCoordinator)?.companyRoleDescription;

            this.taskTypes = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.maintenanceService.taskTypes
            });

            this.resetUsers();

            this.taskStatusList = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.taskStatus
            });

            this.loading = false;
            this.loadingTasks = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
            this.loadingTasks = false;
          }
        })
    );
  }

  setUpDataSource() {
    const dateToTest = this.maintenanceService.addDays(new Date(), this.startFutureDays, false);
    this.dataSource = new DataSource({
      key: 'id',
      loadMode: 'raw',
      load: () => this.allJobTasks.filter(i => this.showNotStarted
        || (this.showCompleted && i.endDate)
        || (this.showCancelled && i.statusId === TaskStatusEnum.Cancelled)
        || (this.showInProgress && (!this.startFutureDays || this.utilsService.getCalendarDaysDifference(i.startDate, dateToTest) <= 0))),
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.taskService.addJobTask(values).subscribe({
            next: (res) => {
              this.upSertJobTasks(res);
              return resolve(res[0]);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) => {
          this.loadingTasks = true;
          this.taskService.updateJobTask(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              this.upSertJobTasks(res);
              this.loadingTasks = false;
              return resolve(res);
            }, error: (err) => {
              this.loadingTasks = false;
              return reject(this.globalService.returnError(err));
            }
          });
        });
      }
    });
  }

  onInitNewRow(e) {
    e.data.startDate = new Date();
    e.data.statusId = TaskStatusEnum.InProgress;
    e.data.userId = this.auth.getCurrentUserId();
    this.selectedTemplateTaskHeaderId = null;
    this.jobVariationId = null;
    this.jobTaskId = null;
    this.currentJobTask = null;

    // only active users
    this.users = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.userService.users.filter(i => i.userTypeId !== UserTypeEnum.Associate
        && i.userTypeId !== UserTypeEnum.Client
        && i.isActive)
    });
  }

  onTasksSaved() {
    // recalc the current activity
    this.inEditMode = false;

    if (this.jobId) {
      this.subscriptions.push(
        this.taskService.calcCurrentActivity(this.jobId)
          .subscribe({
            next: (jobExtra) => {
              this.jobService.currentJobExtra = jobExtra;
              this.jobService.currentActivity = this.maintenanceService
                .activities?.find(i => i.id === jobExtra?.currentActivityId)?.description;
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  onSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0]);
      e.component.close();
    }
  }

  onVariationChanged(cellInfo, event) {
    // for clear event
    if (!event.value) {
      cellInfo.setValue(event.value);
    }
  }

  setJobIdCellValue(rowData, value, currentRowData) {
    if (value) {
      rowData.jobId = value.id;
      rowData.taskMasterId = null;
      rowData.templateTaskHeaderId = null;
      this.taskHeaders = [];
      this.getJobWorkFlows(rowData.jobId, null);
    } else if (currentRowData.jobId) {
      this.getJobWorkFlows(currentRowData.jobId, currentRowData.taskMasterId);
    }
  }

  setTaskTypeCellValue(newData, value) {
    if (value) {
      newData.taskTypeId = value;
      newData.taskMasterId = null;
      newData.templateTaskHeaderId = null;
      this.taskHeaders = [];
    }
  }

  setTaskMasterIdCellValue(rowData, value, currentData) {
    rowData.templateTaskHeaderId = null;
    if (value) {
      rowData.taskMasterId = value;
      const task = this.maintenanceService.taskMasters.find(i => i.id === value);
      rowData.officeComment = task?.defaultOfficeComment;
      rowData.vendorComment = task?.defaultVendorComment;

      this.taskHeaders = [];
      if (task) {
        // add the default comment
        if (!currentData.officeComment || currentData.officeComment.trim() === '') {
          rowData.officeComment = task.defaultOfficeComment;
        }

        this.getTaskHeaders(task.id);
      }

      if (!currentData.endDate) {
        // we can reset the due date
        this.currentTemplateTask = null;

        if (this.taskHeaders && this.taskHeaders.length === 1) {
          rowData.templateTaskHeaderId = this.taskHeaders[0].id;

          this.currentTemplateTask = this.maintenanceService.allTemplateTasks
            .find(i => i.templateTaskHeaderId === rowData.templateTaskHeaderId && i.taskMasterId === value);

          rowData.sendAddendum = this.currentTemplateTask?.includeAddendum;
          rowData.doNotSendCallup = this.currentTemplateTask?.doNotSendCallup;
          rowData.vendorId = this.currentTemplateTask?.vendorId;
        }

        let startDateString = '';
        if (currentData.startDate instanceof Date) {
          startDateString = this.utilsService.convertDateToString(currentData.startDate);
        } else {
          startDateString = currentData.startDate.substr(0, 10);
        }

        if (task) {
          const newDueDate = this.calcDueDate(task.id, startDateString);
          if (newDueDate) {
            rowData.dueDate = newDueDate;
          }
        }
      }
    }
  }

  setTemplateTaskHeaderIdCellValue(rowData, value, currentData) {
    if (value) {
      rowData.templateTaskHeaderId = value;

      this.currentTemplateTask = this.maintenanceService.allTemplateTasks
        .find(i => i.templateTaskHeaderId === value && i.taskMasterId === currentData.taskMasterId);

      this.currentJobWorkflow = this.jobWorkFlows.find(i => i.templateTaskHeaderId === value);

      rowData.sendAddendum = this.currentTemplateTask?.includeAddendum;
      rowData.doNotSendCallup = this.currentTemplateTask?.doNotSendCallup;
      rowData.vendorId = this.currentTemplateTask?.vendorId;

      // we can reset the due date
      if (currentData.startDate && currentData.taskMasterId) {
        const startDateString = this.utilsService.convertDateToString(currentData.startDate);
        const newDueDate = this.calcDueDate(currentData.taskMasterId, startDateString);
        if (newDueDate) {
          rowData.dueDate = newDueDate;
        }
      }
    } else {
      rowData.templateTaskHeaderId = null;
    }

    this.selectedTemplateTaskHeaderId = value;
  }

  getTaskHeaders(taskMasterId: number) {
    // set up the task headers applicable
    const templateTasks = this.maintenanceService.allTemplateTasks.filter(i => i.taskMasterId === taskMasterId);

    templateTasks.forEach(templateTask => {
      const taskHeader = this.allTaskHeaders.find(i => i.id === templateTask.templateTaskHeaderId);
      if (taskHeader) {
        this.taskHeaders.push(taskHeader);
      }
    });
  }

  setStartDateCellValue(newData, value, currentRowData) {
    if (value) {
      newData.startDate = this.utilsService.convertDateToString(value);
      if (currentRowData.statusId === TaskStatusEnum.NotStarted) {
        newData.statusId = TaskStatusEnum.InProgress;
      }

      const startDateString = this.utilsService.convertDateToString(value);
      const todaysDateString = this.utilsService.convertDateToString(new Date());

      if (startDateString < todaysDateString) {
        this.notiService.showWarning('Warning - date in the past');
      } else if (value.getDay() === 6 || value.getDay() === 0) {
        this.notiService.showWarning('Warning - weekend date');
      } else if (this.maintenanceService.holidays.find(i => i.date.toString().substr(0, 10) === startDateString)) {
        this.notiService.showWarning('Warning - date is a holiday');
      }

      newData.startDate = startDateString;
      if (currentRowData.statusId === TaskStatusEnum.NotStarted || currentRowData.statusId === TaskStatusEnum.NotApplicable) {
        newData.statusId = TaskStatusEnum.InProgress;
      }

      if (!currentRowData.endDate) {
        // we can reset the due date
        const newDueDate = this.calcDueDate(currentRowData.taskMasterId, startDateString);
        if (newDueDate) {
          newData.dueDate = newDueDate;
        }
      }
    } else {
      newData.statusId = TaskStatusEnum.NotStarted;
      newData.startDate = null;
      newData.dueDate = null;
      newData.endDate = null;
      newData.isCompleted = false;
    }
  }

  setDueDateCellValue(newData, value, currentRowData) {
    if (value) {
      newData.dueDate = this.utilsService.convertDateToString(value);
    } else {
      newData.dueDate = null;
    }
  }

  calcDueDate(taskMasterId: number, startDateString: string): string {
    const taskMaster = this.maintenanceService.taskMasters.find(i => i.id === taskMasterId);
    if (taskMaster) {
      const startDate =
        new Date(+startDateString.substr(0, 4), +startDateString.substr(5, 2) - 1, +startDateString.substr(8, 2), 0, 0, 0, 0);

      let days = taskMaster.days;

      const templateDays = this.maintenanceService.templateDays
        .find(i => i.templateTaskHeaderId === this.currentTemplateTask?.templateTaskHeaderId
          && i.templateTaskId === this.currentTemplateTask?.id
          && i.templateDaysHeaderId === this.currentJobWorkflow?.templateDaysHeaderId);

      if (templateDays && !templateDays.isOnlyForForecast) {
        days = templateDays.days;
      }

      return this.utilsService.convertDateToString(this.maintenanceService.addDaysExHolidays(startDate, days));
    }
    return null;
  }

  getJobWorkFlows(jobId: number, taskMasterId: number) {
    this.jobWorkFlows = this.jobWorkFlowService.allJobWorkflows.filter(i => i.jobId === jobId);
    this.getTaskHeaders(taskMasterId);
  }

  refresh() {
    // this.loading = true;
    this.jobService.jobs = [];
    this.userService.users = [];
    this.jobService.jobVariations = [];
    this.getData(false);
  }

  getTaskList(options) {
    return {
      store: options.isNewRow ? this.maintenanceService.taskMasters.filter(i => i.isActive) : this.maintenanceService.taskMasters,
      filter: options.data ? ['taskTypeId', '=', options.data.taskTypeId] : null
    };
  }

  setEndDateCellValue(newData, value, currentRowData) {
    // must use formatDate as we get ISO format and lose hours so date can be yesterday
    if (value) {
      newData.endDate = this.utilsService.convertDateToString(value);
      newData.statusId = TaskStatusEnum.Completed;
      newData.isCompleted = true;
    } else {
      newData.statusId = TaskStatusEnum.InProgress;
      newData.isCompleted = false;
      newData.endDate = null;
    }
  }

  setStatusCellValue(newData, value, currentRowData) {
    if (value) {
      newData.statusId = value;

      if (value === TaskStatusEnum.Completed || value === TaskStatusEnum.NotApplicable) {
        newData.endDate = this.utilsService.convertDateToString(new Date());
      } else {
        newData.endDate = null;
      }

      if (value === TaskStatusEnum.NotStarted) {
        newData.startDate = null;
        newData.dueDate = null;
      }
    }
  }

  setIsCompletedCellValue(newData, value, currentRowData) {
    if (value) {
      newData.statusId = TaskStatusEnum.Completed;
      newData.endDate = this.utilsService.convertDateToString(new Date());
    } else {
      newData.statusId = TaskStatusEnum.InProgress;
      newData.endDate = null;
    }
  }

  setSentCellValue(newData, value) {
    if (value) {
      newData.calledDate = this.utilsService.convertDateToString(value);
    } else {
      newData.calledDate = null;
    }
  }

  calculateIsCompleted(data) {
    if (data && data.endDate) {
      return true;
    } else {
      return false;
    }
  }

  calculateContractName(data) {
    if (data && data.jobId) {
      // on hold?
      let contractName = '';
      const job = this.jobs.find(i => i.id === data.jobId);
      if (job) {
        contractName = job.contractName;
      }
      return contractName;
    }
    return '';
  }

  calculateLineManager(data) {
    if (data && data.userId) {
      const lineManager = this.userService.users.find(i => i.id === data.userId)?.lineManager;;
      if (lineManager) {
        return lineManager;
      }
    }
    return '';
  }

  calculateSiteAddress(data) {
    if (data && data.jobId) {
      const job = this.jobs.find(i => i.id === data.jobId);
      if (job) {
        return this.globalService.getJobString(job);
      }
    }
    return '';
  }

  calculateTrade(data) {
    if (data && data.templateTaskHeaderId && data.taskMasterId) {
      const templateTask = this.maintenanceService.allTemplateTasks
        .find(i => i.templateTaskHeaderId === data.templateTaskHeaderId && i.taskMasterId === data.taskMasterId);

      if (templateTask?.tradeId) {
        return this.trades?.find(i => i.id === templateTask.tradeId)?.description ?? '';
      }
    }
    return null;
  }

  calculateTradeRegion(data) {
    if (data && data.jobId) {
      const jobExtra = this.jobService.jobExtras.find(i => i.jobId === data.jobId)
      if (jobExtra && jobExtra.tradeRegionId) {
        return this.maintenanceService.tradeRegions.find(i => i.id === jobExtra.tradeRegionId)?.description ?? '';
      }
    }
    return '';
  }

  calculateHouseType(data) {
    if (data && data.jobId) {
      const job = this.jobs.find(i => i.id === data.jobId);
      if (job) {
        const houseType = this.jobService.houseTypes.find(i => i.id === job.houseTypeId);
        return houseType?.description ?? '';
      }
    }
    return '';
  }

  calculateKeyTaskValue(data) {
    if (data && data.taskMasterId) {
      const taskMaster = this.maintenanceService.taskMasters.find(i => i.id === data.taskMasterId);
      return taskMaster.isKeyTask;
    }
    return false;
  }

  showMyTasksOnlyChanged(e) {
    this.showMyTasksOnly = e.value ? true : false;

    let currentState = JSON.parse(localStorage.getItem('jobtaskgrid'));
    if (currentState) {
      currentState.showMyTasksOnly = this.showMyTasksOnly;
      localStorage.setItem('jobtaskgrid', JSON.stringify(currentState));
    }
    this.getData(true);
  }

  showInProgressChanged(e) {
    this.showInProgress = e.value ? true : false;

    let currentState = JSON.parse(localStorage.getItem('jobtaskgrid'));
    if (currentState) {
      currentState.showInProgress = this.showInProgress;
      localStorage.setItem('jobtaskgrid', JSON.stringify(currentState));
    }
    this.getData(true);
  }

  showNotStartedChanged(e) {
    this.showNotStarted = e.value ? true : false;

    let currentState = JSON.parse(localStorage.getItem('jobtaskgrid'));
    if (currentState) {
      currentState.showNotStarted = this.showNotStarted;
      localStorage.setItem('jobtaskgrid', JSON.stringify(currentState));
    }
    this.getData(true);
  }

  showCompletedChanged(e) {
    this.showCompleted = e.value ? true : false;

    let currentState = JSON.parse(localStorage.getItem('jobtaskgrid'));
    if (currentState) {
      currentState.showCompleted = this.showCompleted;
      localStorage.setItem('jobtaskgrid', JSON.stringify(currentState));
    }
    this.getData(true);
  }

  showCancelledChanged(e) {
    this.showCancelled = e.value ? true : false;

    let currentState = JSON.parse(localStorage.getItem('jobtaskgrid'));
    if (currentState) {
      currentState.showCancelled = this.showCancelled;
      localStorage.setItem('jobtaskgrid', JSON.stringify(currentState));
    }
    this.getData(true);
  }

  showJobsOnHoldChanged(e) {
    this.showJobsOnHold = e.value ? true : false;

    let currentState = JSON.parse(localStorage.getItem('jobtaskgrid'));
    if (currentState) {
      currentState.showJobsOnHold = this.showJobsOnHold;
      localStorage.setItem('jobtaskgrid', JSON.stringify(currentState));
    }
    this.getData(true);
  }

  clearStatePersistance() {
    this.resetPopupVisible = true;
    this.inEditMode = true;
  }

  clearStatePersistanceGo() {
    setTimeout(() => {
      this.inEditMode = true;
      this.resetPopupVisible = false;
      this.resetVariables();
    }, 400);
  }

  resetVariables() {
    this.loading = true;
    this.showMyTasksOnly = true;
    this.showNotStarted = false;
    this.showInProgress = true;
    this.showCompleted = false;
    this.showCancelled = false;
    this.showJobsOnHold = true;

    localStorage.removeItem('jobtaskgrid');
    setTimeout(() => {
      this.loading = false;
      this.setUpDataSource();
      this.inEditMode = false;
    }, 400); // wait for grid
  }

  startFutureDaysChanged(e) {
    this.startFutureDays = e.value ? e.value : null;
    localStorage.setItem('jobtask-start-future-days', JSON.stringify(this.startFutureDays));
    this.setUpDataSource();
  }

  onToolbarPreparing(e, templateName: string, templateName2: string, templateName3: string, templateName4: string, templateName5: string, templateName6: string, toolbarStartDays: string) {
    const toolbarItems = e.toolbarOptions.items;

    if (!this.isPhoneSize) {
      toolbarItems.push({
        location: 'before',
        locateInMenu: 'auto',
        template: templateName
      });
      toolbarItems.push({
        location: 'before',
        locateInMenu: 'auto',
        template: templateName2
      });
      toolbarItems.push({
        location: 'before',
        locateInMenu: 'auto',
        template: templateName3
      });
      toolbarItems.push({
        location: 'before',
        locateInMenu: 'auto',
        template: templateName4
      });
      toolbarItems.push({
        location: 'before',
        locateInMenu: 'auto',
        template: templateName5
      });
      toolbarItems.push({
        location: 'before',
        locateInMenu: 'auto',
        template: templateName6
      });
    }

    if (this.cancelOldTasksModeOn) {
      toolbarItems.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            icon: 'close',
            onClick: this.cancelOldJobTasks.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            stylingMode: 'outlined',
            type: 'default',
            text: 'Cancel selected tasks',
            onClick: this.cancelOldJobTasksGo.bind(this)
          }
        }
      );
    } else {
      toolbarItems.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            text: 'Layouts',
            onClick: this.saveState.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            onClick: this.refresh.bind(this)
          }
        });

      if (!this.isMobile) {
        toolbarItems.unshift(
          {
            location: 'after',
            locateInMenu: 'always',
            widget: 'dxButton',
            options: {
              text: 'Reset Layout',
              onClick: this.clearStatePersistance.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxCheckBox',
            options: {
              text: 'Show full comment',
              value: true,
              rtlEnabled: true,
              onValueChanged: this.showFullComment.bind(this)
            }
          });
      }

      if (this.auth.isAdminOrSuper()) {
        toolbarItems.unshift(
          {
            location: 'after',
            locateInMenu: 'always',
            widget: 'dxButton',
            options: {
              text: 'Cancel ANY Job Tasks (Admin Only)',
              onClick: this.cancelOldJobTasks.bind(this)
            }
          }
        );
      }

      if (this.auth.isAdminOrSuper() || this.auth.getSelectionsPermissions('Tracking') === 'Admin') {
        toolbarItems.unshift(
          {
            location: 'after',
            locateInMenu: 'always',
            widget: 'dxButton',
            options: {
              text: 'Cancel Open Job Tasks',
              onClick: this.cancelOpenJobTasks.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'always',
            widget: 'dxButton',
            options: {
              text: 'Re-Assign Open Job Tasks',
              onClick: this.reassignOpenJobTasks.bind(this)
            }
          }
        );
      }

      toolbarItems.push(
        {
          location: 'after',
          locateInMenu: 'always',
          template: toolbarStartDays
        });
    }
  }

  onInitialized(e) {
    e.component.getView('headerPanel').updateToolbarDimensions = function () { };
  }

  setEditedValue(valueChangedEventArg, cellInfo) {
    cellInfo.setValue(valueChangedEventArg.value);
  }

  calculateTaskSortValue(data) {
    let sortOrder = 0;
    const task = this.maintenanceService.taskMasters.find(i => i.id === data.taskMasterId);
    if (task) {
      sortOrder = task.orderNumber;
    }

    return sortOrder;
  }

  onEditorPreparing(e) {
    if (e.dataField === 'taskTypeId' || e.dataField === 'taskMasterId'
      || e.dataField === 'templateTaskHeaderId' || e.dataField === 'vendorId') {
      e.editorOptions.dropDownOptions = { minWidth: 350 };
    }
    if (e.dataField === 'userId' || e.dataField === 'modifiedUserId') {
      e.editorOptions.dropDownOptions = { minWidth: 220 };
    }
    if (e.dataField === 'jobId' || e.dataField === 'statusId'
      || e.dataField === 'jobVariationId') {
      e.editorOptions.dropDownOptions = { minWidth: 120 };
    }
    if (e.dataType === 'boolean') {
      e.editorOptions.dropDownOptions = { minWidth: 70 };
    }
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      // set isOnHold
      const jobExtra = this.jobService.jobExtras.find(i => i.jobId === e.data.jobId);
      if (jobExtra && jobExtra.cancellationDate) {
        e.rowElement.style.backgroundColor = 'rgb(250, 140, 160)';
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
      } else if (jobExtra && jobExtra.isOnHold) {
        e.rowElement.style.backgroundColor = 'pink';
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
      } else {
        if (jobExtra && jobExtra.trackingColour && jobExtra.trackingColour !== '0') {
          e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
          if (jobExtra.trackingColour === TrackingColourEnum.Orange.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(253, 209, 127, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Yellow.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(253, 253, 139, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Teal.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(132, 247, 237, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Blue.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(147, 198, 247, 0.4)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Magenta.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(208, 157, 250, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Grey.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(0, 0, 0, 0.2)';
          }
        }
        if (e.rowType === 'data' && (e.data.endDate || e.data.statusId === TaskStatusEnum.NotApplicable)) {
          // completed in green or orange
          if (e.data.statusId === TaskStatusEnum.NotApplicable) {
            e.rowElement.style.backgroundColor = 'rgb(230, 245, 220)';
          } else {
            e.rowElement.style.backgroundColor = 'rgb(200, 250, 200)';
          }
          e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
        }
      }
    }
  }

  onCellPrepared(e) {
    if (e.rowType === 'data') {
      if (e.data.statusId === TaskStatusEnum.NotApplicable || e.data.statusId === TaskStatusEnum.Cancelled) {
        e.cellElement.style.textDecoration = 'line-through';
      } else if (e.column.dataField === 'originalDueDate' && !e.data.endDate) {
        if (e.data.originalDueDate < this.todayString) {
          e.cellElement.style.color = 'red';
          e.cellElement.style.fontWeight = 'bold';
        }
      } else if (e.column.dataField === 'dueDate' && !e.data.endDate) {
        if (e.data.dueDate < this.todayString) {
          e.cellElement.style.color = 'red';
          e.cellElement.style.fontWeight = 'bold';
        }
      } else if (e.column.dataField === 'originalDueDate' && !e.data.endDate) {
        const originalDueDate = this.calcOriginalDueDate(e.data);
        if (originalDueDate && originalDueDate < new Date()) {
          e.cellElement.style.color = 'red';
          e.cellElement.style.fontWeight = 'bold';
        }
      } else if ((e.column.dataField === 'calledDate' || e.column.dataField === 'startDate')
        && e.data.startDate && e.data.isAcceptedByVendor) {
        // green text
        e.cellElement.style.color = 'rgb(20, 240, 0)';
      }
    }
  }

  onEditingStart(e) {
    this.inEditMode = true;
    // get notes history
    this.selectedTemplateTaskHeaderId = e.data.templateTaskHeaderId;
    this.jobVariationId = e.data?.jobVariationId;
    if (this.jobVariationId) {
      this.jobVariationStatusId = this.jobVariations.find(i => i.id === this.jobVariationId)?.statusId;
    }
    this.jobTaskId = e.data.id;
    this.currentJobTask = e.data;
    this.jobId = e.data.jobId;
    this.vendorId = e.data.vendorId; // for send
    this.vendorComment = e.data.vendorComment;
    this.sendAddendum = e.data.sendAddendum;
    this.currentTemplateTask = this.maintenanceService.allTemplateTasks
      .find(i => i.templateTaskHeaderId === e.data.templateTaskHeaderId && i.taskMasterId === e.data.taskMasterId);

    if (this.jobId) {
      const job = this.jobs.find(i => i.id === this.jobId);
      this.callUpSubject = 'Job# ' + job.jobNumber + ' - ' + this.globalService.getJobString(job);

      if (job.warningNote && job.warningNote.trim() !== '') {
        this.notiService.showWarning(job.warningNote);
      }

      // check if on hold
      const jobExtra = this.jobService.jobExtras?.find(i => i.jobId === this.jobId);
      if (jobExtra.isOnHold) {
        this.notiService.showWarning('Job is ON HOLD');
      }
      if (jobExtra.cancellationDate) {
        this.notiService.showWarning('Job is Cancelled');
      }
    }

    this.taskHeaders = [];
    this.currentTaskMaster = this.maintenanceService.taskMasters.find(i => i.id === e.data.taskMasterId);
    if (this.currentTaskMaster) {
      this.getJobWorkFlows(e.data.jobId, this.currentTaskMaster.id);
      this.currentJobWorkflow = this.jobWorkFlows.find(i => i.templateTaskHeaderId === e.data.templateTaskHeaderId);
    } else {
      this.currentJobWorkflow = null;
    }
    this.currentTaskTitle = this.currentTaskMaster?.taskTitle;

    // only active users
    this.users = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.userService.users.filter(i => i.userTypeId !== UserTypeEnum.Associate
        && i.userTypeId !== UserTypeEnum.Client
        && (i.isActive || i.id === e.data.userId))
    });
  }

  onEditCancelled() {
    this.inEditMode = false;
  }

  resetUsers() {
    // all active users or users with tasks
    const users: User[] = [];

    this.userService.users.filter(i => i.userTypeId !== UserTypeEnum.Associate
      && i.userTypeId !== UserTypeEnum.Client).forEach(user => {
        if (user.isActive || this.allJobTasks.find(i => i.userId === user.id)) {
          users.push(user);
        }
      });

    this.users = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => users
    });

    this.jobVariationId = null; // reset variation
  }

  showCommentHistory() {
    this.commentHistoryPopupVisible = true;

    this.commentHistoryDataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.taskService.getJobTaskComments(this.jobTaskId).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  closeCommentHistory() {
    this.commentHistoryPopupVisible = false;
  }

  upSertJobTasks(newItems: Task[]) {
    newItems.forEach(element => {
      let foundId = this.allJobTasks.findIndex(i => i.id === element.id);
      if (foundId >= 0) {
        if ((!this.showCancelled && element.statusId === TaskStatusEnum.Cancelled)
          || (!this.showNotStarted && !element.startDate)
          || (!this.showCompleted && element.endDate)
          || (this.showMyTasksOnly && element.userId !== this.auth.getCurrentUserId())) {
          this.allJobTasks.splice(foundId, 1);
        } else {
          this.allJobTasks.splice(foundId, 1, element);
        }
      } else {
        this.allJobTasks.push(element);
      }
    });

    this.grid.instance.refresh();
  }

  cancelClickHandler() {
    this.grid.instance.cancelEditData();
  }

  saveClickHandler(e) {
    if (this.grid.instance.hasEditData() && this.changes && this.changes[0]?.type === 'insert' && this.taskHeaders && this.taskHeaders.length
      && !this.changes[0].data.templateTaskHeaderId && this.changes[0].data.jobId && this.changes[0].data.taskMasterId) {
      this.savePopupVisible = true;
    } else {
      this.saveData();
    }
  }

  saveCheckDone() {
    this.savePopupVisible = false;
    this.saveData();
  }

  saveData() {
    if (this.jobVariationId) {
      // save changes to variation
      const thisVariation = this.jobVariations.find(i => i.id === this.jobVariationId);
      if (this.jobVariationStatusId !== thisVariation?.statusId) {
        this.updatingVariation = true;
        this.subscriptions.push(
          this.jobService.updateVariationStatus(this.jobVariationId, this.jobVariationStatusId)
            .subscribe({
              next: () => {
                thisVariation.statusId = this.jobVariationStatusId;
                this.jobVariationId = null;
                this.updatingVariation = false;
                this.grid.instance.saveEditData();
              },
              error: (err) => {
                this.updatingVariation = false;
                this.notiService.notify(err);
              }
            })
        );
      } else {
        this.grid.instance.saveEditData();
      }
    } else {
      this.grid.instance.saveEditData();
    }
  }

  sendTask() {
    // send call-up for this task
    if (this.grid.instance.hasEditData()) {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      if (!this.vendorComment || this.vendorComment.trim() === '') {
        this.vendorComment = 'Please be advised: ' + this.currentTaskTitle + ' has been assigned to you.';
      }
      this.sendPopupVisible = true;
      this.selectedJobDocs = [];
      this.setVendorEmail();

      this.loadingDocs = true;
      this.getPurchaseOrdersForJob();
      this.subscriptions.push(
        this.jobService.getJobDocuments(this.jobId, false)
          .subscribe({
            next: () => {
              this.jobDocuments = this.jobService.jobDocuments;
              this.loadingDocs = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.jobDocuments = [];
              this.loadingDocs = false;
            }
          })
      );
    }
  }

  getPurchaseOrdersForJob() {
    const permissionAmounts = this.auth.getSelectionsPermissions('PurchaseOrders');
    if (permissionAmounts === 'Read' || permissionAmounts === 'Write' || permissionAmounts === 'Admin' || this.auth.isAdminOrSuper()) {
      this.selectedPurchaseOrderId = this.currentJobTask.purchaseOrderId;
      this.purchaseOrders = [];
      this.subscriptions = this.subscriptions.concat(
        this.maintenanceService.getPurchaseOrdersAndCostCentresForJob(this.jobId).subscribe(
          res => {
            this.purchaseOrders = res?.filter(i => !i.isCancelled);
          },
          err => {
            this.notiService.notify(err);
          })
      );
    }
  }

  setVendorEmail() {
    this.callUpEmail = '';
    if (this.vendorId) {
      const vendor = this.vendors.find(i => i.id === this.vendorId);
      if (vendor) {
        if (vendor.quoteRequestEmail && vendor.quoteRequestEmail.trim() !== '' && this.currentTaskMaster?.isQuoteRequest) {
          this.callUpEmail = vendor.quoteRequestEmail;
        } else {
          this.callUpEmail = vendor.callUpEmail ? vendor.callUpEmail : vendor.email;
        }
      }
    }
  }

  attachmentSelectionChanged(e): void {
    // get all leaves as new list
    this.selectedJobDocs = e.component.getSelectedRowKeys('all').filter(this.globalService.onlyUnique);
  }

  onVendorSelectionChanged(e) {
    this.vendorId = e.selectedItem?.id;
    this.setVendorEmail();
  }

  sendCallUp() {
    // first save any data
    this.loadingDocs = true;
    this.updatedData = {};
    this.updatedData.vendorId = this.vendorId;
    this.updatedData.vendorComment = this.vendorComment;
    this.updatedData.sendAddendum = this.sendAddendum;
    this.updatedData.purchaseOrderId = this.selectedPurchaseOrderId;

    this.subscriptions.push(
      this.taskService.updateJobTask(this.jobTaskId.toString(), this.updatedData)
        .subscribe({
          next: () => {
            this.currentJobTask.purchaseOrderId = this.selectedPurchaseOrderId;
            this.saveAttachedDocs();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  saveAttachedDocs() {
    this.subscriptions.push(
      this.jobService.addJobTaskDocuments(this.jobTaskId, { keyIds: this.selectedJobDocs })
        .subscribe({
          next: () => {
            this.sendCallUpGo();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  clearVendorDetails() {
    // first save any data
    this.loadingDocs = true;
    this.updatedData = {};
    this.updatedData.vendorId = null;
    this.updatedData.vendorComment = '';

    this.subscriptions.push(
      this.taskService.updateJobTask(this.jobTaskId.toString(), this.updatedData)
        .subscribe({
          next: () => {
            this.loadingDocs = false;
            this.sendPopupVisible = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  calculateCallUpDocsType(data) {
    let result = false;

    if (data.callUpDocsTypeId && this.currentTemplateTask?.callUpDocsTypes && this.currentTemplateTask?.callUpDocsTypes.length) {
      const foundType = this.currentTemplateTask?.callUpDocsTypes.find(i => i === data.callUpDocsTypeId);

      if (foundType) {
        result = true;
      }
    }
    return result;
  }

  sendCallUpGo() {
    // send the task call-up
    this.subscriptions.push(
      this.taskService.sendCallUpForOneTask(this.jobTaskId, this.callUpEmail, this.callUpSubject, this.ccToSelf, this.selectedPurchaseOrderId)
        .subscribe({
          next: () => {
            this.loadingDocs = false;
            this.sendPopupVisible = false;
            this.notiService.showSuccess('Email sent.');
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );

    // const jobTaskIds: number[] = [];
    // jobTaskIds.push(this.jobTaskId);
    // const emailAddresses: string[] = [];
    // emailAddresses.push(this.callUpEmail);

    // this.subscriptions.push(
    //   this.taskService.sendCallUps(this.jobId, jobTaskIds, this.ccToSelf, false, emailAddresses)
    //     .subscribe({
    //       next: (res) => {
    //         this.loadingDocs = false;
    //         this.sendPopupVisible = false;
    //         this.notiService.showSuccess('Email queued.');
    //       },
    //       error: (err) => {
    //         this.notiService.notify(err);
    //         this.loadingDocs = false;
    //       }
    //     })
    // );
  }

  saveState() {
    this.inEditMode = true;
    let currentState = JSON.parse(localStorage.getItem('jobtaskgrid'));

    currentState.showMyTasksOnly = this.showMyTasksOnly;
    currentState.showNotStarted = this.showNotStarted;
    currentState.showInProgress = this.showInProgress;
    currentState.showCompleted = this.showCompleted;
    currentState.showCancelled = this.showCancelled;
    currentState.showJobsOnHold = this.showJobsOnHold;

    const modalRef = this.modalService.open(StateStoreComponent, { windowClass: 'modal-stateStore', scrollable: true });
    modalRef.componentInstance.stateStoreTypeId = StateStoreTypeEnum.TaskGrid;
    modalRef.componentInstance.stateString = JSON.stringify(currentState);

    modalRef.result.then((state: StateStore) => {
      this.loading = true;
      if (state) {
        currentState = JSON.parse(state.stateString);
        localStorage.setItem('jobtaskgrid', state.stateString);
        this.getStateVariables(currentState);
        this.refresh();
      } else {
        // load default
        this.resetVariables();
      }
    }, () => {
      this.inEditMode = false;
    });
  }

  getStateVariables(currentState: any) {
    if (currentState) {
      this.showMyTasksOnly = currentState.showMyTasksOnly ?? true;
      this.showNotStarted = currentState.showNotStarted ?? false;
      this.showInProgress = currentState.showInProgress ?? true;
      this.showCompleted = currentState.showCompleted ?? false;
      this.showCancelled = currentState.showCancelled ?? false;
      this.showJobsOnHold = currentState.showJobsOnHold ?? true;
      this.selectedJobTaskIds = [];
      currentState['selectedRowKeys'] = [];
    }
  }

  jobSearch() {
    const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-search' });
    modalRef.componentInstance.searchType = JobSearchTypeEnum.AllJobs;

    modalRef.result.then((jobNumber) => {
      if (jobNumber) {
        const jobId = this.jobService.jobs.find(i => i.jobNumber === jobNumber);
        this.jobFilterValue = jobId.id;
      }
    }, () => { });
  }

  calculateJobSortValue(data) {
    const job = this.jobs.find(i => i.id === data.jobId);
    if (job) {
      return job.jobNumber;
    }
    return '';
  }

  calculateJobGroupValue(data): string {
    const job = this.jobs.find(i => i.id === data.jobId);
    if (job) {
      return job.jobNumber;
    }
    return '';
  }

  calculateJobGroupDisplay(data): string {
    const job = this.jobs.find(i => i.jobNumber === data.key[0]);
    if (job) {
      const jobExtra = this.jobService.jobExtras.find(i => i.jobId === job.id);
      if (jobExtra?.maintenanceCompleteDate) {
        return job.jobNumber + ' - ' + job.jobAddressString + ' (Maintenance Complete)';
      } else if (jobExtra && jobExtra.currentActivityId) {
        let currentActivity = this.maintenanceService
          .activities?.find(i => i.id === jobExtra?.currentActivityId)?.description;

        if ((!currentActivity || currentActivity === '') && !job.salesDate) {
          currentActivity = 'Pending Sale';
        }

        return job.jobNumber + ' - ' + job.jobAddressString + ' (' + currentActivity + ')';
      } else if (!job.salesDate) {
        return job.jobNumber + ' - ' + job.jobAddressString + ' (Pending Sale)';
      } else {
        return job.jobNumber + ' - ' + job.jobAddressString;
      }
    }

    return '';
  }

  calculateVariationTitle(data) {
    if (data && data.jobVariationId) {
      const jobVariation = this.jobVariations.find(i => i.id === data.jobVariationId);
      if (jobVariation) {
        return jobVariation.title;
      }
    }
    return '';
  }

  calculateVariationStatus(data) {
    if (data && data.jobVariationId) {
      const jobVariation = this.jobVariations.find(i => i.id === data.jobVariationId);
      if (jobVariation) {
        return this.variationStatus.find(i => i.id === jobVariation.statusId)?.status;
      }
    }
    return '';
  }

  calculateMasterJob(data) {
    if (data && data.jobId) {
      const thisJob = this.jobs.find(i => i.id === data.jobId);
      if (thisJob && thisJob.masterJobId) {
        return this.jobs.find(i => i.id === thisJob.masterJobId)?.jobNumber;
      }
      return thisJob?.jobNumber;
    }
    return '';
  }

  calculateSalesRep(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SalesRep);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculateLiaison(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.LiaisonOfficer);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculatePrestartOfficer(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.PreStartOfficer);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculateSiteManager(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SiteManager);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculateConstructionCoordinator(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.ConstructionCoordinator);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculateAreaConstructionManager(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.AreaConstructionManager);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculateDesigner(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.Designer);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculateSalesEstimator(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SalesEstimator);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculateScheduler(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SchedulerConstructionEstimator);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculateDraftsperson(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.Draftsperson);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculatePreConstructionCoordinator(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.PreConstructionCoordinator);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculateAccounts(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.Accounts);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculateSalesAdministrator(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SalesAdministrator);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculateMaintenanceCoordinator(data) {
    if (data && data.jobId) {
      const jobRole = this.taskService.allJobRoles.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.MaintenanceCoordinator);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  calculateDaysSinceCreated(data) {
    if (data && data.createDate) {
      return this.maintenanceService.getCalendarDaysDifferenceExHolidays(data.endDate ?? new Date(), data.createDate) ?? 0;
    }
    return null;
  }

  calculateDaysSinceStart(data) {
    if (data && data.startDate) {
      return this.maintenanceService.getCalendarDaysDifferenceExHolidays(data.endDate ?? new Date(), data.startDate) ?? 0;
    }
    return null;
  }

  calculateContractsRequiredByDate(data): Date {
    if (data && data.jobId) {
      const job = this.jobs.find(i => i.id === data.jobId);
      if (job && job.contractsRequiredByDate) {
        return new Date(job.contractsRequiredByDate);
      }
    }
    return null;
  }

  calculateDaysToContractsRequired(data): number {
    if (data && data.jobId) {
      const job = this.jobs.find(i => i.id === data.jobId);
      if (job?.contractsRequiredByDate) {
        return this.utilsService.getCalendarDaysDifference(job.contractsRequiredByDate, new Date()) ?? 0;
      }
    }
    return null;
  }

  calculateCurrentActivity(data) {
    if (data && data.jobId) {
      const thisJob = this.jobs.find(i => i.id === data.jobId);
      if (thisJob) {
        return thisJob.currentActivityDesc;
      }
    }
    return '';
  }

  calculateDivision(data) {
    if (data && data.jobId) {
      const thisJob = this.jobs.find(i => i.id === data.jobId);
      if (thisJob) {
        return this.maintenanceService.divisions.find(i => i.id === thisJob.divisionId)?.description ?? '';
      }
    }
    return '';
  }

  calculateJobWarning(data) {
    if (data && data.jobId) {
      const thisJob = this.jobs.find(i => i.id === data.jobId);
      return thisJob?.warningNote ?? '';
    }
    return '';
  }

  calculateVariationEstimator(data) {
    if (data && data.jobVariationId) {
      const jobVariation = this.jobVariations.find(i => i.id === data.jobVariationId);
      if (jobVariation && jobVariation.assignedUserId) {
        return this.userService.users.find(i => i.id === jobVariation.assignedUserId)?.fullName;
      }
    }
    return '';
  }

  cancelOpenJobTasks() {
    this.inEditMode = true;
    const modalRef = this.modalService.open(CancelJobTasksComponent, { windowClass: 'modal-edit' });

    modalRef.result.then(() => {
      this.refresh();
    }, () => {
      this.inEditMode = false;
    });
  }

  reassignOpenJobTasks() {
    this.inEditMode = true;
    const modalRef = this.modalService.open(ReassignJobTasksComponent, { windowClass: 'modal-edit' });

    modalRef.result.then(() => {
      this.refresh();
    }, () => {
      this.inEditMode = false;
    });
  }

  cancelOldJobTasks() {
    this.cancelOldTasksModeOn = !this.cancelOldTasksModeOn;
    this.loading = true; // first to set grid into selection mode
    setTimeout(() => {
      this.loading = false;
      if (this.cancelOldTasksModeOn) {
        this.inEditMode = true;
      } else {
        this.inEditMode = false;
      }

      setTimeout(() => {
        this.selectedJobTaskIds = [];
      }, 400);
    }, 400);
  }

  cancelOldJobTasksGo() {
    if (this.selectedJobTaskIds.length) {
      this.cancelTasksButton = 'Cancel ' + this.selectedJobTaskIds.length + ' tasks';
      this.includeStartedTasksForCancel = false;
      this.cancelTasksPopupVisible = true;
    } else {
      this.notiService.showWarning('No tasks selected');
    }
  }

  cancelOldJobTasksRun() {
    this.cancelTasksPopupVisible = false;
    this.loading = true;
    this.subscriptions.push(
      this.taskService.cancelJobTasksByIds(this.includeStartedTasksForCancel, this.selectedJobTaskIds)
        .subscribe({
          next: () => {
            this.cancelOldTasksModeOn = false;
            this.loading = false;
            this.refresh();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
            this.inEditMode = false;
          }
        })
    );
  }

  showFullComment() {
    this.showFullComments = !this.showFullComments;
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  calcOriginalDueDate(data) {
    if (data.startDate) {
      let startDateString = '';
      if (data.startDate instanceof Date) {
        startDateString = this.utilsService.convertDateToString(data.startDate);
      } else {
        startDateString = data.startDate.substr(0, 10);
      }

      const task = this.maintenanceService.taskMasters.find(i => i.id === data.taskMasterId);
      if (task) {
        const newDueDate = this.calcDueDate(task.id, startDateString);
        if (newDueDate) {
          return new Date(newDueDate);
        }
      }
    }
    return null;
  }

  calculateSentFromQueueStatus(data: Task): string {
    if (data.sentFromQueueDate) {
      return 'Sent';
    } else if (data.calledDate) {
      return 'Queued';
    }
    return '';
  }

  openVariationDropdown(e) {
    this.variationsDataSource = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.jobVariations?.filter(i => i.jobId === e.data.jobId)
    });
  }

  setVariationIdCellValue(rowData, value) {
    if (value) {
      rowData.jobVariationId = value.id;
    } else {
      rowData.jobVariationId = null;
    }
    this.jobVariationId = null;
    this.jobVariationStatusId = null;
    setTimeout(() => {
      this.jobVariationId = rowData.jobVariationId;
      if (this.jobVariationId) {
        this.jobVariationStatusId = this.jobVariations.find(i => i.id === this.jobVariationId)?.statusId;
      }
    }, 200);
  }

  goToJobData(e) {
    if (e.row.data.jobId) {
      this.jobService.setCurrentJob(this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber);
      localStorage.setItem('jobANNX-costcentreId', e.row.data.costCentreId);
      this.globalService.setAreaSelected('job-data');
      this.router.navigateByUrl('/job-data');
    }
  }

  deleteTaskPopup(e) {
    // we may want to cancel or mark not applicable
    this.deletePopupVisible = true;
    this.inEditMode = true;
    this.recordToDelete = e.row.data;
    this.jobId = e.row.data.jobId;
  }

  deleteTasksGo(deleteRecord: boolean) {
    this.deletePopupVisible = false;

    setTimeout(() => {
      this.inEditMode = true;

      if (deleteRecord) {
        this.cancelTask();
      } else {
        const updateRecord =
        {
          statusId: TaskStatusEnum.NotApplicable,
          startDate: this.recordToDelete.startDate ? this.utilsService.convertDateToString(this.recordToDelete.startDate) : this.utilsService.convertDateToString(new Date()),
          dueDate: this.recordToDelete.dueDate ? this.utilsService.convertDateToString(this.recordToDelete.dueDate) : this.utilsService.convertDateToString(new Date()),
          endDate: this.utilsService.convertDateToString(new Date())
        };

        this.updateJobTaskWithData(updateRecord);
      }
    }, 400); // wait for popup to hide
  }

  updateJobTaskWithData(updateRecord: object) {
    this.deleteInProgress = true;
    this.subscriptions.push(
      this.taskService.updateJobTask(this.recordToDelete.id.toString(), updateRecord)
        .subscribe({
          next: (res) => {
            this.deleteInProgress = false;
            this.onTasksSaved();
            this.upSertJobTasks(res);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.deleteInProgress = false;
            this.inEditMode = false;
          }
        })
    );
  }

  cancelTask() {
    const modalRef = this.modalService.open(QuestionComponent, { backdrop: 'static', keyboard: false });
    modalRef.componentInstance.message = 'Are you sure? You may be stopping a workflow';
    modalRef.componentInstance.acceptMessage = 'Continue to cancel';

    modalRef.result.then(() => {
      const updateRecord =
      {
        statusId: TaskStatusEnum.Cancelled,
        startDate: this.recordToDelete.startDate ? this.utilsService.convertDateToString(this.recordToDelete.startDate) : this.utilsService.convertDateToString(new Date())
      };
      this.updateJobTaskWithData(updateRecord);
    }, () => {
      this.inEditMode = false;
    });
  }

  checkTaskPopup(e) {
    // we may want to cancel or mark not applicable
    this.checkPopupVisible = true;
    this.recordToDelete = e.row.data;
    this.jobId = e.row.data.jobId;
    this.inEditMode = true;
  }

  checkTasksGo() {
    this.deleteInProgress = true;
    this.checkPopupVisible = false;

    setTimeout(() => {
      this.inEditMode = true;
      const updateRecord =
      {
        statusId: TaskStatusEnum.Completed,
        startDate: this.recordToDelete.startDate ? this.utilsService.convertDateToString(this.recordToDelete.startDate) : this.utilsService.convertDateToString(new Date()),
        dueDate: this.recordToDelete.dueDate ? this.utilsService.convertDateToString(this.recordToDelete.dueDate) : this.utilsService.convertDateToString(new Date()),
        endDate: this.utilsService.convertDateToString(new Date())
      };

      this.subscriptions.push(
        this.taskService.updateJobTask(this.recordToDelete.id.toString(), updateRecord)
          .subscribe({
            next: (res) => {
              this.deleteInProgress = false;
              this.onTasksSaved();
              this.upSertJobTasks(res);
            },
            error: (err) => {
              this.notiService.notify(err);
              this.deleteInProgress = false;
              this.inEditMode = false;
            }
          })
      );
    }, 400); // wait for popup to hide
  }

  openVariation(e) {
    // open variation
    this.jobVariationId = e.row.data.id;
    this.openVariationSelected();
  }

  openVariationSelected() {
    const variation = this.jobVariations.find(i => i.id === this.jobVariationId);
    const variationNumber = variation?.variationNumber;
    const jobNumber = this.jobs.find(i => i.id === variation?.jobId)?.jobNumber;
    this.updatingVariation = true;

    this.subscriptions.push(
      this.jobService.downloadJobVariation(jobNumber, variationNumber, this.canReadAmountsPermission)
        .subscribe({
          next: (res) => {
            this.updatingVariation = false;
            if (res.attachment) {
              this.globalService.convertAndSave(res.attachment, jobNumber);
            } else {
              this.notiService.showInfo('Variation has no items');
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.updatingVariation = false;
          }
        })
    );
  }

  attachDocs() {
    const modalRef = this.modalService.open(TaskDocumentsComponent,
      { backdrop: 'static', keyboard: false, backdropClass: 'backdropClass' });
    modalRef.componentInstance.jobTask = this.currentJobTask;
    modalRef.componentInstance.useCache = false;

    modalRef.result.then(() => {
    }, () => {
    });
  }

  editDueDateOrComment(cellValue) {
    // console.log(cellValue);
    this.editDueDateOrCommentPopupVisible = true;
    this.jobTaskId = cellValue.key;
    const jobTask = this.allJobTasks.find(i => i.id === this.jobTaskId);
    const task = this.maintenanceService.taskMasters.find(i => i.id === jobTask.taskMasterId);
    this.dueDate = jobTask.dueDate;
    this.officeComment = jobTask.officeComment;
    this.currentTaskTitle = task.taskTitle;
    this.inEditMode = true;
  }

  updateDueDate() {
    setTimeout(() => {
      this.inEditMode = true;
      this.editDueDateOrCommentPopupVisible = false;
      const rowIndex = this.grid.instance.getRowIndexByKey(this.jobTaskId);
      this.grid.instance.cellValue(rowIndex, 'dueDate', this.dueDate);
      this.grid.instance.cellValue(rowIndex, 'officeComment', this.officeComment);
      this.grid.instance.saveEditData();
    }, 400);
  }

  resetCountdown() {
    this.minuteCountdown = 10; // Reset the countdown
  }
}
