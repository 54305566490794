import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { UserService } from '../../services/felixApi/user.service';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { formatDate } from 'devextreme/localization';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'js-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.scss']
})
export class HolidaysComponent implements OnInit, OnDestroy {

  loading = true;
  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  users: any;

  constructor(
    private maintenanceService: MaintenanceService,
    private userService: UserService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    protected gridService: GridService,
    private utilsService: UtilsService
  ) {
    this.setDateCellValue = this.setDateCellValue.bind(this);
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getData() {
    this.subscriptions.push(
      this.userService.getCurrCompUsers(true)
        .subscribe({
          next: (users) => {
            this.users = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => users
            });

            this.loading = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.getHolidays(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.addHoliday(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.updateHoliday(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.maintenanceService.deleteHoliday(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onInitNewRow(e) {
    e.data.isUsedInCashflow = true;
  }

  setDateCellValue(newData, value, currentRowData) {
    if (value) {
      newData.date = this.utilsService.convertDateToString(value);
    } else {
      newData.date = null;
    }
  }
}
