export class JobField {
    public id: number;
    public jobId: number;
    public trackingFieldGroupId: number;
    public trackingFieldId: number;
    public textValue: string;
    public trackingFieldLookupId: number;
    public fieldName: string;
    public orderNumber: number;
    public trackingFieldTypeId: number;
    public modifiedUserId: number;
    public modifiedDate: Date;
    public trackingFieldOrderNo: number;
    public colourId: number;

    constructor(jobId: number, trackingFieldId: number, textValue: string) {
        this.id = 0;
        this.jobId = jobId;
        this.trackingFieldId = trackingFieldId;
        this.textValue = textValue;
    }
}
